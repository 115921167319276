.user-details {
    display: flex;
    align-items: center;

    justify-content: center;
    background: var(--Schemes-On-Primary, #FFF);

    padding: 24px;
}

.user-avatar {
    margin-right: 69px;
}

.user-avatar img {
    height: 216px;
}

.user-name {
    color: #000;
    text-align: center;
    font-family: Outfit;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 12px;
}

.user-location {
    color: #000;
    text-align: center;
    font-family: Outfit;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    display: flex;
    align-items: center;
}

.user-location img {
    height: 33px;
    margin-right: 10px;
}

.user-container {
    padding: 130px 150px 102px 150px;

    display: flex;
    gap: 33px;
}

.user-container h2 {
    color: #000;
    font-family: Lato;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    /* margin-bottom: 14px; */
    margin-bottom: 41px;
}

.user-container .subtitle {
    color: #000;
    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 41px;
}

.user-container .right {
    width: 400px;
}

.review-card {
    margin-bottom: 40px;
}

.left {
    flex: 1;
}