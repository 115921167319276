import * as types from "../actions/index";

const initialState = {
  profile: null,
  reviews: {
    total: 0,
    per_page: 10,
    offset: 0,
    to: 0,
    last_page: 1,
    page: 1,
    from: 0,
    data: []
  },
  review_count: null
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case types.GET_USER.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.GET_USER.SUCCESS:
      return {
        ...state,
        ...payload,
        loading: false,
      };
    case types.GET_USER.FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
