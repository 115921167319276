.review-card {
    display: flex;
    flex-direction: column;
}

.review-card .user-detail {
    border-radius: 20px;
    background: var(--Schemes-On-Primary, #FFF);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px 15px 15px;
    flex: 1;
    margin-bottom: 13px;
}

.review-card .user-detail .detail-left {
    display: flex;
    align-items: center;
}

.review-card .user-detail .detail-left img.user-avatar {
    height: 70px;
    margin-right: 20px;
}

.review-card .user-detail .detail-left>div {
    display: flex;
    flex-direction: column;
}

.review-card .user-detail .detail-left .user-name {
    color: #000;
    font-family: Lato;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    /* margin-bottom: 7px; */
}

.review-card .user-detail .detail-left .user-review-count-and-location {
    display: flex;
    align-items: center;
}

.review-card .user-detail .detail-left .user-review-count {
    margin-right: 30px;

    color: #000;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.review-card .user-detail .detail-left .user-location {
    display: flex;
    align-items: center;

    color: #000;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.review-card .user-detail .detail-left .user-location img {
    height: 15px;
    margin-right: 8px;
}

.review-card .user-detail .detail-right {
    display: flex;
    align-items: center;
}

.review-card .user-detail .detail-right .review-stars {
    /* width: 115px; */
    margin-right: 25px;
    display: flex;
    gap: 9px;
}

.review-card .user-detail .detail-right .user-linkedin-verified {
    display: flex;
    align-items: center;
    gap: 7px;

    color: #000;
    font-family: Outfit;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
}

.review-card .review-content {
    border-radius: 20px;
    background: var(--Schemes-On-Primary, #FFF);
    padding: 28px 63px 28px 45px;
}

.review-card .review-content .review-title-and-date {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 29px;
}

.review-card .review-content .review-title-and-date h3 {
    color: #000;
    text-align: center;
    font-family: Lato;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.review-card .review-content .review-title-and-date .review-date {
    color: #000;
    text-align: center;
    font-family: Lato;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.review-card .review-content .review-text {
    color: #000;
    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.review-card .review-content .divider {
    height: 1px;
    background: rgba(0, 0, 0, 0.17);
    margin-top: 43px;
    margin-bottom: 30px;
}

.review-card .review-content .review-ctas {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.review-card .review-content .review-ctas .cta-like {
    display: flex;
    align-items: center;
    gap: 6px;

    color: #000;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.review-card .review-content .review-ctas .cta-share {
    display: flex;
    align-items: center;
    gap: 9px;

    color: #000;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.review-card .review-content .review-reply {
    border-radius: 47px;
    background: rgba(221, 214, 239, 0.19);
    margin-top: 57px;
    padding: 20px 28px 20px 72px;
    position: relative;
}

.review-card .review-content .review-reply .heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 29px;
}

.review-card .review-content .review-reply .heading .review-from {
    color: #000;
    text-align: center;
    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.review-card .review-content .review-reply .heading .review-date {
    color: #000;
    text-align: center;
    font-family: Lato;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.review-card .review-content .review-reply .reply-text {
    color: #000;
    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.review-card .review-content .review-reply .reply-icon {
    position: absolute;
    top: 27px;
    left: 25px;
}

.review-card .review-content .review-reply .reply-icon img {
    height: 15px;
}

.review-card .review-content .review-reply .reply-side-pole {
    position: absolute;
    left: 0px;
    top: 0px;

    border-radius: 20px;
    background: #2E5676;

    height: 100%;
    width: 7px;
}