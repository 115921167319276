import { HttpMethod } from "./baseHttpService";

export default class CompanyService {
  constructor(
    apiService
  ) {
    this.apiService = apiService;
  }

  async getCompanies(query, category, cursor) {
    const response = await this.apiService.request({
      method: HttpMethod.GET,
      url: `company?query=${query}&category=${category}&cursor=${cursor}`,
    });
    return response;
  }

  async getCompany(slug, brief = false) {
    const query = brief ? '?type=brief' : '';
    const response = await this.apiService.request({
      method: HttpMethod.GET,
      url: `company/${slug}${query}`,
    });
    return response;
  }
}


