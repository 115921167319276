import * as types from "../actions/index";

const initialState = {
  loading: false,
  error: null,
  categories: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case types.GET_CATEGORIES.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.GET_CATEGORIES.SUCCESS:
      return {
        ...state,
        loading: false,
        categories: payload,
      };
    case types.GET_CATEGORIES.FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case types.GET_LANDING_DATA.SUCCESS:
      return {
        ...state,
        categories: payload.categories,
      };
    default:
      return state;
  }
};
