import React from "react";
import clsx from "clsx";
import { IconLeftArrow } from "../../icons/IconLeftArrow";
import { IconRightArrow } from "../../icons/IconRightArrow";
import latestReviewsStyles from "./LatestReviews.module.css";
import { useSelector } from "react-redux";

import { Swiper, SwiperSlide } from 'swiper/swiper-react.mjs';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/modules/pagination.css';
import 'swiper/modules/navigation.css';
import 'swiper/swiper.css';
import { getUserLongName } from "../../utils";
import { ReviewStars } from "../ReviewStars";
import { Link } from "react-router-dom";

export const LatestReviews = () => {
  const { latestReviews } = useSelector(({ General }) => General.LandingPage)

  return (
    <section className={clsx("wide-container", latestReviewsStyles['reviews'])}>
      <div className={latestReviewsStyles['review-inner']}>
        <div className={latestReviewsStyles['review-extra']}>
          <h3 className={latestReviewsStyles['title']}>
            Latest Reviews
          </h3>
          <div className={latestReviewsStyles['subtitle']}>
            Cum doctus civibus efficiantur in imperdiet deterruisset.
          </div>
          <button>Connect Now</button>
        </div>
        <div
          className={latestReviewsStyles['review-boxes']}
        >
          {
            latestReviews.length > 0
              ?
              (
                <Swiper
                  grabCursor={true}
                  slidesPerView={3}
                  spaceBetween={28}
                  navigation={{ nextEl: `.${latestReviewsStyles['review-nav-arrows']} .right`, prevEl: `.${latestReviewsStyles['review-nav-arrows']} .left` }}
                  pagination={{
                    el: `.${latestReviewsStyles['review-nav-bullets']}`,
                    bulletActiveClass: `${latestReviewsStyles['active']}`,
                    bulletClass: `${latestReviewsStyles['bullet']}`,
                    type: 'bullets',
                    clickable: true,
                  }}
                  modules={[Navigation, Pagination]}
                  autoHeight={false}
                >
                  {
                    latestReviews.map((review) => {
                      return (
                        <SwiperSlide className={latestReviewsStyles['review-box']}>
                          <div className={latestReviewsStyles['review-img']} style={{ backgroundImage: 'url(/img/rectangle-18.svg)' }}></div>
                          <h4 className={latestReviewsStyles['review-author']}>
                            <Link to={`/user/${review.author.id}`}>{getUserLongName(review.author)}</Link>
                          </h4>
                          <div className={latestReviewsStyles['review-author-title']}>
                            <Link to={`/company/${review.company.slug}`}>
                              {review.company.name}
                            </Link>
                          </div>
                          <div className={latestReviewsStyles['review-stars']}>
                            <ReviewStars rating={review.rating} starWidth={22.27} />
                          </div>
                          <div className={latestReviewsStyles['review-text']}>
                            {review.review}
                          </div>
                        </SwiperSlide>
                      )
                    })
                  }
                </Swiper>
              )
              :
              null
          }
        </div>

        <div className={latestReviewsStyles['review-navigation']}>
          <div className={latestReviewsStyles['review-nav-arrows']}>
            <IconLeftArrow className="left" />
            <IconRightArrow className="right" />
          </div>
          <div className={latestReviewsStyles['review-nav-bullets']}></div>
        </div>
      </div>

      <div className={latestReviewsStyles['quotation']}><span>“</span></div>
    </section>
  );
};
