import React, { useCallback, useEffect, useState } from "react";
import { Footer } from "../../components/Footer";
import { NavWithSearch } from "../../components/NavWithSearch";
import { Link, useParams } from "react-router-dom";
import { IconStar } from "../../icons/IconStar";
import companyPageStyles from "./CompanyPage.module.css";
import { ReviewCard } from "../../components/ReviewCard";
import { useDispatch, useSelector } from "react-redux";
import { getCompanyReviewsAsync, postReviewsAsync, updateReviewsAsync } from "../../actions/review.action";
import { getCompanyAsync } from "../../actions/company.action";
import { ReviewStars } from "../../components/ReviewStars";
import { InputStars } from "../../components/InputStars";
import { showToast } from "../../utils";

export const CompanyPage = () => {
    const initialState = {
        company: null,
        rating_counts: {},
        reviews: [],
        last_page: 1,
        page: 1,
        offset: 0,
        highlighted: null
    };
    const { slug } = useParams();
    const [page, setPage] = useState(1);
    const [loaded, setLoaded] = useState(false);
    const [state, setState] = useState(initialState);
    const dispatch = useDispatch();

    const [updateTrigger, triggerUpdate] = useState(1)

    const { profile } = useSelector(({ User }) => User);

    const initialFormState = {
        title: "",
        review: "",
        rating: 0,
    };
    const [values, setValues] = useState(initialFormState);

    const onRatingChange = (rating) => {
        console.log(rating)
        setValues({
            ...values,
            rating: rating
        })
    }

    const onChange = (event) => {
        const { name, value } = event.target;
        setValues({
            ...values,
            [name]: value
        })
    }

    const onSubmit = useCallback((event) => {
        event.preventDefault();
        if (!state.user_review?.id) {
            dispatch(
                postReviewsAsync(
                    {
                        ...values,
                        company_id: state.company.id
                    },
                    (data) => {
                        // setValues(initialFormState);
                        showToast("Review submitted successfully", "success")
                        triggerUpdate((number) => number + 1);
                    },
                    (error) => console.log("Error:", error),
                )
            )
        } else {
            dispatch(
                updateReviewsAsync(
                    state.user_review?.id,
                    {
                        ...values,
                        company_id: state.company.id
                    },
                    (data) => {
                        // setValues(initialFormState);
                        showToast("Review updated successfully", "success")
                        triggerUpdate((number) => number + 1);
                    },
                    (error) => console.log("Error:", error),
                )
            )
        }

    }, [state.user_review, state.company, values])

    useEffect(() => {
        setLoaded(false);
        setState(initialState);
        dispatch(getCompanyAsync(slug, false, (data) => {
            setState({
                ...state,
                ...data,
            })
            if (data.user_review) {
                const { title, rating, review } = data.user_review
                setValues({ title, review, rating, })
            }
            setLoaded(true);
        }))
    }, [slug, updateTrigger])

    useEffect(() => {
        if (loaded)
            dispatch(getCompanyReviewsAsync(slug, '', page, 20, (data) => {
                const { highlighted, pagination: { data: reviews, last_page, page, offset } } = data;
                setState({
                    ...state,
                    reviews,
                    last_page,
                    page,
                    offset,
                    highlighted
                })
            }))
    }, [loaded, page])

    useEffect(() => {
        document.documentElement.classList.add('light-bg')
        return () => document.documentElement.classList.remove('light-bg')
    }, [])

    const getRatingPercentage = useCallback((number) => {
        const numberCount = state.rating_counts[`count_${number}`];
        const totalCount = state.company.rating_count;
        const value = numberCount / totalCount * 100;
        return Math.round(value)
        // if (Math.round(value) === value) return value;
        // return value.toFixed(2);
    }, [state])

    if (!loaded) return null

    if (!state.company) return null

    // console.log(state)

    return (
        <>
            <NavWithSearch />

            <section className={companyPageStyles['breadcrumbs']}>
                <Link to="/categories">Categories</Link>
                <span>&gt;</span>
                <Link>{state.company.category.title}</Link>
            </section>


            <section className={companyPageStyles['company-details']}>
                <div className={companyPageStyles['company-logo']}>
                    <img src="/img/rectangle-36-1.png" alt="" />
                </div>
                <div className={companyPageStyles['general-detail']}>
                    <div className={companyPageStyles['top-row']}>
                        <h2>{state.company.name}</h2>
                        <img src="/img/star-3-1.png" alt="" />
                    </div>
                    <div className={companyPageStyles['bottom-row']}>
                        <span className={"review-count"}> {state.company.rating_count} Reviews</span>
                        <span className={"review-grade"}>Excellent</span>
                        <span className={companyPageStyles['verified']}><img src="/img/checked-9709605-1.svg" alt="" /> <span>Verified Company</span></span>
                    </div>
                </div>
                <div className={companyPageStyles['website']}>
                    <div className={companyPageStyles['address']}>{state.company.website}</div>
                    <a href={state.company.website} target="_blank">Visit Site</a>
                </div>
            </section>

            <section className={companyPageStyles['company-review-container']}>
                <div className={companyPageStyles['left']}>
                    {
                        profile
                            ?
                            <div className={companyPageStyles['write-review-card']}>
                                <h3>{state.user_review?.id ? "Update Review" : "Write Review"}</h3>
                                <div className={companyPageStyles['input-group']}>
                                    <div className={companyPageStyles['label']}>Title</div>
                                    <div className={companyPageStyles['input-con']}>
                                        <input onChange={onChange} value={values.title} className={companyPageStyles['input']} type="text" name="title" id="" />
                                    </div>
                                </div>
                                <div className={companyPageStyles['input-group']}>
                                    <div className={companyPageStyles['label']}>Review</div>
                                    <div className={companyPageStyles['input-con']}>
                                        <textarea onChange={onChange} value={values.review} rows={4} name="review" id=""></textarea>
                                    </div>
                                </div>
                                <div className={companyPageStyles['input-group']}>
                                    <div className={companyPageStyles['label']}>Rating</div>
                                    <span className={companyPageStyles['input-stars']}>
                                        <InputStars defaultValue={values.rating} onChange={onRatingChange} />
                                    </span>
                                </div>
                                <button className={companyPageStyles['submit-review']} onClick={onSubmit}>
                                    Submit Review
                                </button>
                            </div>
                            :
                            null
                    }

                    <div className={companyPageStyles['review-summary']}>
                        <div className={companyPageStyles['title-bar']}>
                            <h3><span>Reviews</span> <IconStar color="#FFA43C" /> <span>{state.company.avg_rating}</span></h3>
                            <div>Total: {state.company.rating_count}</div>
                        </div>

                        <div className={companyPageStyles['divider']}></div>

                        <div className={companyPageStyles['review-row']}>
                            <div className={"review-name"}>5 star</div>
                            <div className={companyPageStyles['review-stars']}>
                                <ReviewStars rating={5} starWidth={26.27} />
                            </div>
                            <div className={"review-percent"}>{getRatingPercentage(5)}%</div>
                        </div>

                        <div className={companyPageStyles['review-row']}>
                            <div className={"review-name"}>4 star</div>
                            <div className={companyPageStyles['review-stars']}>
                                <ReviewStars rating={4} starWidth={26.27} />
                            </div>
                            <div className={"review-percent"}>{getRatingPercentage(4)}%</div>
                        </div>

                        <div className={companyPageStyles['review-row']}>
                            <div className={"review-name"}>3 star</div>
                            <div className={companyPageStyles['review-stars']}>
                                <ReviewStars rating={3} starWidth={26.27} />
                            </div>
                            <div className={"review-percent"}>{getRatingPercentage(3)}%</div>
                        </div>

                        <div className={companyPageStyles['review-row']}>
                            <div className={"review-name"}>2 star</div>
                            <div className={companyPageStyles['review-stars']}>
                                <ReviewStars rating={2} starWidth={26.27} />
                            </div>
                            <div className={"review-percent"}>{getRatingPercentage(2)}%</div>
                        </div>

                        <div className={companyPageStyles['review-row']}>
                            <div className={"review-name"}>1 star</div>
                            <div className={companyPageStyles['review-stars']}>
                                <ReviewStars rating={1} starWidth={26.27} />
                            </div>
                            <div className={"review-percent"}>{getRatingPercentage(1)}%</div>
                        </div>
                    </div>

                    {/* <div className={companyPageStyles['filter-and-sort']}>
                        <div className={companyPageStyles['filter']}>
                            <span>Filter</span>
                            <img src="/img/filter-3841466-1.svg" alt="" />
                        </div>

                        <div className={companyPageStyles['sort']}>
                            <span>Sort:</span>
                            <div className={companyPageStyles['sort-select']}>
                                Most Relevant
                            </div>
                        </div>
                    </div> */}

                    <div className={companyPageStyles['reviews-card']}>
                        {
                            state.reviews.map(review => (<ReviewCard review={review} />))
                        }
                    </div>
                </div>
                <div className={companyPageStyles['right']}>

                    <div className={companyPageStyles['banner']}>
                        <img src="/img/write-a-review-image.png" alt="" />
                    </div>

                    <div className={companyPageStyles['about-company']}>
                        <h3 className={companyPageStyles['about-heading']}>About {state.company.name}</h3>
                        <div className={companyPageStyles['about-text']}>
                            {state.company.description}
                        </div>
                        <div className={companyPageStyles['divider']}></div>
                        <h3 className={companyPageStyles['contact-heading']}>Contact</h3>
                        <div className={companyPageStyles['contact-location']}>
                            <img className={companyPageStyles['geo-icon']} src="/img/group-8.png" alt="" />
                            <div className={companyPageStyles['contact-address']}>
                                Stoneridge Mall Road 6230 <br />
                                94588<br />
                                Pleasanton<br />
                                United States
                            </div>
                        </div>
                    </div>

                    <div className={companyPageStyles['encounters']}>
                        <h3 className={companyPageStyles['encounter-heading']}><img src="/img/qolty-black-1.png" alt="" /> <span>Encounters</span></h3>
                        <div className={companyPageStyles['divider']}></div>
                        <div className={companyPageStyles['encounter-list']}>
                            <div className={companyPageStyles['encounter']}>
                                <div className={companyPageStyles['title']}>Do we answer reviews?</div>
                                <div className={companyPageStyles['dropdown-icon']}><img src="/img/material-symbols-arrow-drop-down-8.svg" alt="" /></div>
                            </div>

                            <div className={companyPageStyles['encounter']}>
                                <div className={companyPageStyles['title']}>How does feedback affect your products?</div>
                                <div className={companyPageStyles['dropdown-icon']}><img src="/img/material-symbols-arrow-drop-down-8.svg" alt="" /></div>
                            </div>

                            <div className={companyPageStyles['encounter']}>
                                <div className={companyPageStyles['title']}>How do we manage fraudulent reviews?</div>
                                <div className={companyPageStyles['dropdown-icon']}><img src="/img/material-symbols-arrow-drop-down-8.svg" alt="" /></div>
                            </div>

                            <div className={companyPageStyles['encounter']}>
                                <div className={companyPageStyles['title']}>Are reviews vetted before publication? </div>
                                <div className={companyPageStyles['dropdown-icon']}><img src="/img/material-symbols-arrow-drop-down-8.svg" alt="" /></div>
                            </div>

                            <div className={companyPageStyles['encounter']}>
                                <div className={companyPageStyles['title']}>How do reviews help consumers? </div>
                                <div className={companyPageStyles['dropdown-icon']}><img src="/img/material-symbols-arrow-drop-down-8.svg" alt="" /></div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

            <Footer />
        </>
    );
};
