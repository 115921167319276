export const HttpMethod = {
  POST: "POST",
  GET: "GET",
  PUT: "PUT",
  PATCH: "PATCH",
  UNLINK: "UNLINK",
  OPTIONS: "OPTIONS",
  DELETE: "DELETE",
}

export const HttpStatus = {
  BadRequest: 400,
  Created: 201,
  Ok: 200,
  Forbidden: 403,
  Unauthorized: 401,
  NotFound: 404,
  Timeout: 100,
}

export class HttpError extends Error {
  constructor(
    statusCode,
    data
  ) {
    super("Http Error occurred");
    this.statusCode = statusCode
    this.data = data
  }

  getStatusCode() {
    return this.statusCode;
  }

  getData() {
    return this.data;
  }
}

export default class BaseHTTPService {
  constructor(service) {
    this.service = service
  }

  async request(request) {
    try {
      const response = await this.service.request({
        method: request.method,
        url: request.url,
        responseType: "json",
        data: request.payload,
      });
      return response;
    } catch (err) {
      console.log(err);
      const axiosError = err;
      console.error("Axios error occurred", axiosError);
      if (axiosError.response) {
        throw new HttpError(
          axiosError.response.status,
          axiosError.response.data
        );
      } else {
        throw new HttpError(HttpStatus.Timeout, {
          message:
            "An error occurred while processing request. Check your internet connection",
        });
      }
    }
  }
}
