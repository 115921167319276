.get-through {
  position: relative;
  background: linear-gradient(90deg, #2C5776 0%, #4E4376 119.99%);
  box-shadow: 18px 1rem 2rem 14px #0c0c0d66;
  /* height: 500px; */
  padding: 38px 42px 60px 763px;
  margin-bottom: 400px;
}

.get-through .left-banner {
  position: absolute;
  top: -170px;
  left: -10px;
}

.get-through .left-banner img {
  height: 987px;
}

.get-through .right {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.get-through h2 {
  color: #FFF;
  font-family: Outfit;
  font-size: 96px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  margin-bottom: 82px;
}

.get-through h2 span {
  color: #FFF;
  font-family: Outfit;
  font-size: 96px;
  font-weight: 700;

  background: linear-gradient(0deg, #EDF200 0%, #EDC18E 59.5%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.get-through .right p {
  color: #FFF;
  font-family: Lato;
  font-size: 24px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 200px;
}

.get-through button {
  font-size: 20px;
  line-height: 1;

  border-radius: 10px;
  background: rgba(45, 86, 118, 0.95);
  box-shadow: 0px 8px 12px 6px rgba(0, 0, 0, 0.15), 0px 4px 4px 0px rgba(0, 0, 0, 0.30);
  border: none;

  color: #ffffff;
  padding: 18.5px 25px;
}
