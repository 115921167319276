.footer {
  background: linear-gradient(90deg, #2E5676 0%, #484776 100%);
}

.footer .logo-and-links {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding: 170px 36px 43px 36px;
}

.footer .logo-and-featured-image {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 110px;
}

.footer .about-links,
.community-links {
  margin-right: 110px;
}

.footer img.logo {
  height: 49px;
}

.footer img.featured-image {
  height: 335px;
}

.footer .footer-nav-section {
  display: flex;
  flex-direction: column;
}

.footer .footer-nav-section h4 {
  color: #FFF;
  font-family: Lato;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin-bottom: 57px;
}

.footer .footer-nav-section a {
  color: #FFF;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 42px;
}

.footer .footer-nav-section a:hover {
  text-decoration: underline;
}

.footer .footer-nav-section a:last-child {
  margin-bottom: 0px;
}

.footer .social-media-icons {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  row-gap: 25px;
  column-gap: 32px;
}

.footer .social-media-icons img {
  height: 51px;
  width: 51px;
}

.footer .strip {
  background: linear-gradient(270deg, #C3F8F1 2.27%, #DDD6EF 63.96%);
  padding: 10px 23px;
  display: flex;
  justify-content: space-between;
}

.footer .copyright {
  color: #000;
  font-family: Lato;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.footer .other-links {
  display: flex;
  gap: 110px;
}

.footer .other-links a {
  color: #000;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}