import * as types from './user.types.js'
import { userService } from '../services'
import { showErrorMessage } from '../utils'

export const getUserAsync = (
  successCallback,
  errorCallback
) => {
  return async (dispatch) => {
    dispatch({ type: types.GET_USER.REQUEST })
    try {
      const {
        data: { data: _data },
      } = await userService.getUser()
      dispatch({ type: types.GET_USER.SUCCESS, payload: _data })
      successCallback?.(_data)
    } catch (err) {
      dispatch({ type: types.GET_USER.FAILURE, payload: err })
      console.log('act err', err)
      showErrorMessage(err)
      errorCallback?.()
    }
  }
}

export const getUserByIdAsync = (
  id,
  successCallback,
  errorCallback
) => {
  return async (dispatch) => {
    dispatch({ type: types.GET_USER_BY_ID.REQUEST })
    try {
      const {
        data: { data: _data },
      } = await userService.getUserById(id)
      dispatch({ type: types.GET_USER_BY_ID.SUCCESS, payload: _data })
      successCallback?.(_data)
    } catch (err) {
      dispatch({ type: types.GET_USER_BY_ID.FAILURE, payload: err })
      console.log('act err', err)
      showErrorMessage(err)
      errorCallback?.()
    }
  }
}

export const updateUserAsync = (
  data,
  successCallback,
  errorCallback
) => {
  return async (dispatch) => {
    dispatch({ type: types.UPDATE_USER.REQUEST })
    try {
      const {
        data: { data: _data },
      } = await userService.updateUser(data)
      dispatch({ type: types.UPDATE_USER.SUCCESS, payload: _data })
      successCallback?.(_data);
    } catch (err) {
      dispatch({ type: types.UPDATE_USER.FAILURE, payload: err })
      console.log('act err', err)
      showErrorMessage(err)
      errorCallback?.()
    }
  }
}

export const updatePasswordAsync = (
  data,
  successCallback,
  errorCallback
) => {
  return async (dispatch) => {
    dispatch({ type: types.UPDATE_PASSWORD.REQUEST })
    try {
      const response = await userService.updatePassword(data)
      dispatch({ type: types.UPDATE_PASSWORD.SUCCESS })
      successCallback?.();
    } catch (err) {
      dispatch({ type: types.UPDATE_PASSWORD.FAILURE, payload: err })
      console.log('act err', err)
      showErrorMessage(err)
      errorCallback?.()
    }
  }
}
