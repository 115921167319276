import React from "react";
import clsx from "clsx";
import exploreCategoriesStyles from "./ExploreCategories.module.css";
import { Categories } from "../Categories";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export const ExploreCategories = () => {
  const { topCategories } = useSelector(({ General }) => General.LandingPage)

  const navigate = useNavigate();

  return (
    <section className={clsx("container", exploreCategoriesStyles['explore'])}>
      <div className={exploreCategoriesStyles['heading']}>
        <h2>Explore Categories</h2>
        <button onClick={() => navigate("/categories")}>View All</button>
      </div>

      <Categories categories={topCategories} />
    </section>
  );
};
