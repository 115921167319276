import React, { useEffect, useState } from "react";
import { Footer } from "../../components/Footer";
import { NavWithSearch } from "../../components/NavWithSearch";
import signupPageStyles from "./SignupPage.module.css";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import { useDispatch } from "react-redux";
import { registerAsync } from "../../actions/auth.action";

export const SignupPage = () => {
    const initialState = {
        first_name: '',
        last_name: '',
        email: '',
        password: '',
    }
    const [state, setState] = useState(initialState)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const onChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.value
        })
    }

    const onSubmit = (event) => {
        event.preventDefault()
        dispatch(registerAsync(state, (user) => {
            setState(initialState)
        }))
    }

    useEffect(() => {
        document.documentElement.classList.add('light-bg')
        return () => document.documentElement.classList.remove('light-bg')
    }, [])

    return (
        <>
            <NavWithSearch />


            <section className={signupPageStyles['login-container']}>
                <h1>Look through. Write down. Discover Organizations</h1>

                <div className={signupPageStyles['form']}>
                    <div className={signupPageStyles['left']}>
                        <img src="/img/login-banner.png" alt="" />
                    </div>
                    <div className={signupPageStyles['right']}>
                        <h3>Create a new account</h3>

                        <div className={signupPageStyles['input-group']} style={{ marginBottom: 17 }}>
                            <label htmlFor="">First name</label>
                            <div className={signupPageStyles['input-with-icon']}>
                                <input
                                    type="text"
                                    // placeholder="John"
                                    name="first_name"
                                    value={state.first_name}
                                    onChange={onChange}
                                />
                                <div className={signupPageStyles['icon']}>
                                    <img src="/img/frame.svg" alt="" />
                                </div>
                            </div>
                        </div>

                        <div className={signupPageStyles['input-group']} style={{ marginBottom: 17 }}>
                            <label htmlFor="">Last name</label>
                            <div className={signupPageStyles['input-with-icon']}>
                                <input
                                    type="text"
                                    // placeholder="John"
                                    name="last_name"
                                    value={state.last_name}
                                    onChange={onChange}
                                />
                                <div className={signupPageStyles['icon']}>
                                    <img src="/img/frame.svg" alt="" />
                                </div>
                            </div>
                        </div>

                        <div className={signupPageStyles['input-group']} style={{ marginBottom: 17 }}>
                            <label htmlFor="">Email address</label>
                            <div className={signupPageStyles['input-with-icon']}>
                                <input
                                    type="text"
                                    // placeholder="abcd@email.com"
                                    name="email"
                                    value={state.email}
                                    onChange={onChange}
                                />
                                <div className={signupPageStyles['icon']}>
                                    <img src="/img/frame.svg" alt="" />
                                </div>
                            </div>
                        </div>

                        <div className={signupPageStyles['input-group']}>
                            <label htmlFor="">Password</label>
                            <div className={signupPageStyles['input-with-icon']}>
                                <input
                                    type="password"
                                    // placeholder="Enter your password"
                                    name="password"
                                    value={state.password}
                                    onChange={onChange}
                                />
                                <div className={signupPageStyles['icon']}>
                                    <img src="/img/frame-1.svg" alt="" />
                                </div>
                            </div>
                        </div>

                        <button className={signupPageStyles['login']} onClick={onSubmit}>
                            Signup Now
                        </button>

                        <div className={signupPageStyles['divider']}>
                            <div className={signupPageStyles['divider-line']}></div>
                            <span className={signupPageStyles['divider-text']}>OR</span>
                            <div className={signupPageStyles['divider-line']}></div>
                        </div>

                        <button className={clsx(signupPageStyles['outlined-button'], signupPageStyles['signup'])}>
                            Login Now
                        </button>

                        {/* <button className={clsx(signupPageStyles['outlined-button'], signupPageStyles['continue-with-google'])}>
                            <span>Continue With Google</span>
                            <img src="/img/kisspng-google-logo-logo-logo-5ade7dc753b015-9317679115245306313.png" alt="" />
                        </button>

                        <button className={clsx(signupPageStyles['outlined-button'], signupPageStyles['continue-with-facebook'])}>
                            <span>Sign in with Facebook</span>
                            <img src="/img/icon.svg" alt="" />
                        </button> */}
                    </div>
                </div>
            </section>




            <Footer />
        </>
    );
};
