.explore {
  margin-bottom: 238px;
}

.explore .heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 23px;
}

.explore h2 {
  color: #FFF;

  text-shadow: 0px 8px 12px rgba(0, 0, 0, 0.15), 0px 4px 4px rgba(0, 0, 0, 0.30);
  font-family: Outfit;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: 110px;
  text-transform: capitalize;
}

.explore button {
  font-size: 20px;
  line-height: 1;

  border-radius: 10px;
  background: rgba(45, 86, 118, 0.95);
  box-shadow: 0px 8px 12px 6px rgba(0, 0, 0, 0.15), 0px 4px 4px 0px rgba(0, 0, 0, 0.30);
  border: none;

  color: #ffffff;
  padding: 18.5px 58px;
}