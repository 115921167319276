.home-banner {
  display: flex;
  gap: 58px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 210px;
}

.home-banner .left {
  flex: 1;
}

.home-banner .text {
  margin-bottom: 95px;
}

.home-banner h1 {
  color: #FFF;
  text-shadow: 0px 8px 12px rgba(0, 0, 0, 0.15), 0px 4px 4px rgba(0, 0, 0, 0.30);
  font-size: 87px;
  line-height: 110px;
  text-transform: capitalize;
}

.home-banner h1 span {
  background: linear-gradient(180deg, #CF8BF3 42.79%, #FFFC00 58.82%, #FFFC00 71.26%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Outfit;
  font-style: normal;
  font-size: 87px;
  line-height: 110px;
  text-transform: capitalize;
}

.home-banner p {
  color: #FFF;
  font-family: Lato;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 28px;
}

.home-banner .right {
  flex: 1;
}