import React from "react";
import { IconStar } from "../../icons/IconStar";

export const ReviewStars = ({ starWidth = 47, rating = 0 }) => {
  return (
    <>
      <IconStar color={rating >= 1 ? '#FFA43C' : '#F2ECE5'} width={starWidth} />
      <IconStar color={rating >= 2 ? '#FFA43C' : '#F2ECE5'} width={starWidth} />
      <IconStar color={rating >= 3 ? '#FFA43C' : '#F2ECE5'} width={starWidth} />
      <IconStar color={rating >= 4 ? '#FFA43C' : '#F2ECE5'} width={starWidth} />
      <IconStar color={rating >= 5 ? '#FFA43C' : '#F2ECE5'} width={starWidth} />
    </>
  );
};
