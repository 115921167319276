.user-details {
    display: flex;
    align-items: center;

    /* justify-content: center; */
    background: var(--Schemes-On-Primary, #FFF);

    padding: 45px 145px;
}

.user-avatar {
    margin-right: 33px;
}

.user-avatar img {
    height: 169px;
}

.user-name {
    color: #000;
    text-align: center;
    font-family: Outfit;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 12px;
}

.user-location {
    color: #000;
    text-align: center;
    font-family: Outfit;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    display: flex;
    align-items: center;
}

.user-location img {
    height: 33px;
    margin-right: 10px;
}

.user-stats {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 100px;
}

.user-stat {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
}

.user-stat-number {
    color: #000;
    text-align: center;
    font-family: Outfit;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.user-stat-name {
    color: #000;
    text-align: center;
    font-family: Outfit;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.user-container {
    padding: 130px 150px 102px 150px;

    display: flex;
    gap: 33px;
}

.user-container h2 {
    color: #000;
    font-family: Lato;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    /* margin-bottom: 14px; */
    margin-bottom: 41px;
}

.user-container .subtitle {
    color: #000;
    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 41px;
}

.user-container .right {
    width: 400px;
    display: flex;
    justify-content: center;
}

.user-container .right img {
    /* width: 90%; */
}

.settings-card {
    border-radius: 20px;
    background: var(--Schemes-On-Primary, #FFF);
    padding: 40px 55px;

    /* display: flex;
    flex-direction: column;
    gap: 30px; */
}

.left {
    flex: 1;
}

.input-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    margin-bottom: 35px;

    /* justify-content: stretch; */
}

.input-group .label {
    color: #000;
    text-align: center;
    font-family: Outfit;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    margin-bottom: 18px;
}

.input-group .input-con {
    width: 100%;
}

.input-group input {
    width: 100%;
    border-radius: 20px;
    border: 1px solid #000;
    background: var(--Schemes-On-Primary, #FFF);

    font-size: 20px;
    line-height: 1;
    padding: 20px;
}

.apply-updates {
    border-radius: 20px;
    background: #6F79D2;
    border: none;
    padding: 20px;

    color: var(--Schemes-On-Primary, #FFF);
    text-align: center;
    font-family: Outfit;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    cursor: pointer;

    margin-top: 60px;
}

.upload-photo {
    width: 100%;

    border-radius: 20px;
    background: #6F79D2;
    border: none;
    padding: 20px;

    color: var(--Schemes-On-Primary, #FFF);
    text-align: center;
    font-family: Outfit;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    cursor: pointer;
}