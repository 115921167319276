.banner {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 69px;
}

.banner h1 {
    color: #000;
    text-align: center;
    font-family: Outfit;
    font-size: 60px;
    font-style: normal;
    font-weight: 700;
    line-height: 110px;
    text-transform: capitalize;

    max-width: 558px;
}

.banner h1 span {
    background: linear-gradient(179deg, #7303C0 71.58%, #EC38BC 89.41%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: Outfit;
    font-size: 60px;
    font-style: normal;
    font-weight: 700;
    line-height: 110px;
    text-transform: capitalize;
}

.banner img {
    border-radius: 30px;
    box-shadow: 0px 16px 48px -8px rgba(45, 86, 118, 0.95);
    height: 406px;
}

.category-box {
    padding: 64px 166px 196px;
}