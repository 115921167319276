import React, { useEffect, useState } from "react";
import { Footer } from "../../components/Footer";
import { NavWithSearch } from "../../components/NavWithSearch";
import loginPageStyles from "./LoginPage.module.css";
import { Link, useNavigate } from "react-router-dom";
import clsx from "clsx";
import { useDispatch } from "react-redux";
import { loginAsync } from "../../actions/auth.action";

export const LoginPage = () => {
    const initialState = {
        email: '',
        password: '',
    }
    const [state, setState] = useState(initialState)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const onChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.value
        })
    }

    const onSubmit = (event) => {
        event.preventDefault()
        dispatch(loginAsync(state, (user) => {
            console.log(user)
            setState(initialState)
            navigate('/dashboard')
        }))
    }

    useEffect(() => {
        document.documentElement.classList.add('light-bg')
        return () => document.documentElement.classList.remove('light-bg')
    }, [])

    return (
        <>
            <NavWithSearch />


            <section className={loginPageStyles['login-container']}>
                <h1>Look through. Write down. Discover Organizations</h1>

                <div className={loginPageStyles['form']}>
                    <div className={loginPageStyles['left']}>
                        <img src="/img/login-banner.png" alt="" />
                    </div>
                    <div className={loginPageStyles['right']}>
                        <h3>Login into your account</h3>

                        <div className={loginPageStyles['input-group']} style={{ marginBottom: 17 }}>
                            <label htmlFor="">Email address</label>
                            <div className={loginPageStyles['input-with-icon']}>
                                <input
                                    type="text"
                                    placeholder="abcd@email.com"
                                    name="email"
                                    value={state.email}
                                    onChange={onChange}
                                />
                                <div className={loginPageStyles['icon']}>
                                    <img src="/img/frame.svg" alt="" />
                                </div>
                            </div>
                        </div>

                        <div className={loginPageStyles['input-group']}>
                            <label htmlFor="">Password</label>
                            <div className={loginPageStyles['input-with-icon']}>
                                <input
                                    type="password"
                                    placeholder="Enter your password"
                                    name="password"
                                    value={state.password}
                                    onChange={onChange}
                                />
                                <div className={loginPageStyles['icon']}>
                                    <img src="/img/frame-1.svg" alt="" />
                                </div>
                            </div>
                        </div>

                        <div className={loginPageStyles['forgot-password']}>
                            <Link to="/forgot-password">Forgot password?</Link>
                        </div>


                        <button className={loginPageStyles['login']} onClick={onSubmit}>
                            Login now
                        </button>

                        <div className={loginPageStyles['divider']}>
                            <div className={loginPageStyles['divider-line']}></div>
                            <span className={loginPageStyles['divider-text']}>OR</span>
                            <div className={loginPageStyles['divider-line']}></div>
                        </div>

                        <button className={clsx(loginPageStyles['outlined-button'], loginPageStyles['signup'])}>
                            Signup Now
                        </button>

                        <button className={clsx(loginPageStyles['outlined-button'], loginPageStyles['continue-with-google'])}>
                            <span>Continue With Google</span>
                            <img src="/img/kisspng-google-logo-logo-logo-5ade7dc753b015-9317679115245306313.png" alt="" />
                        </button>

                        <button className={clsx(loginPageStyles['outlined-button'], loginPageStyles['continue-with-facebook'])}>
                            <span>Sign in with Facebook</span>
                            <img src="/img/icon.svg" alt="" />
                        </button>
                    </div>
                </div>
            </section>




            <Footer />
        </>
    );
};
