import * as types from "../actions/index";
import { getAuthToken } from "../utils";

const initialState = {
  loading: false,
  error: null,
  token: getAuthToken() || '',
  user: null
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case types.SIGN_UP.REQUEST:
    case types.SIGN_IN.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.SIGN_UP.SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case types.SIGN_IN.SUCCESS:
      return {
        ...state,
        loading: false,
        ...payload,
      };
    case types.GET_USER.SUCCESS:
      return {
        ...state,
        user: payload.profile,
      };
    case types.SIGN_UP.FAILURE:
    case types.SIGN_IN.FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};
