import axios from "axios";
import { getAuthToken, logout } from "../utils";
import BaseHTTPService, {
  HttpStatus,
} from "./baseHttpService";

class ApiService extends BaseHTTPService {
  constructor(
    baseUrl
  ) {
    super(
      axios.create({
        baseURL: baseUrl,
        withCredentials: false,
        headers: {
          "Content-Type": "application/json",
        },
      })
    );
    
    this.service.interceptors.request.use((config) => {
      const token = getAuthToken();

      if (!token) return config;

      if (config.headers) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    });
  }

  async request(requestProps) {
    try {
      return await super.request(requestProps);
    } catch (e) {
      const httpError = e;

      switch (httpError.getStatusCode()) {
        case HttpStatus.Forbidden:
          throw httpError;
        // return this.routerService.navigate("/");
        case HttpStatus.Unauthorized:
          return logout();
        default:
          throw httpError;
      }
    }
  }
}

export default ApiService;
