import React from "react";
import categoriesStyles from "./Categories.module.css";

export const Categories = ({ categories }) => {
  return (
    <div className={categoriesStyles['categories']}>
      {
        categories.map((category, index) => (
          <div className={categoriesStyles['category']}>
            <img alt="Garden image" src="/img/garden-image.png" />
            <span>{category.title}</span>
          </div>
        ))
      }
    </div>
  );
};
