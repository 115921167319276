import React from "react";
import clsx from "clsx";
import { Icon10 } from "../../icons/Icon10";
import { Icon33 } from "../../icons/Icon33";
import searchComponentStyles from "./SearchComponent.module.css";

export const SearchComponent = ({ className }) => {
  return (
    <div className={clsx(searchComponentStyles['search-component'], className)}>
      <input placeholder="Company or Category Search" />
      <button className={searchComponentStyles['hamburger-icon']}>
        <Icon10 />
      </button>
      <button className={searchComponentStyles['search-icon']}>
        <Icon33 />
      </button>
    </div>
  );
};
