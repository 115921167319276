import React from "react";
import clsx from "clsx";
import { Link, useNavigate } from "react-router-dom";
import homeNavStyles from "./HomeNav.module.css";
import { useSelector } from "react-redux";

export const HomeNav = () => {
  const { profile } = useSelector(({ User }) => User);

  const navigate = useNavigate();

  return (
    <section className={clsx("container", homeNavStyles['topnav'])}>
      <div className={homeNavStyles['logo']}>
        <Link to="/">
          <img src="/img/logo.png" />
        </Link>
      </div>

      <div className={homeNavStyles['links']}>
        <Link to="/about-us">About</Link>
        <Link to="/categories">Categories</Link>
        {!profile ? <Link to="/login">Login</Link> : null}
        <button onClick={() => navigate("/for-businesses")}>For Businesses</button>
      </div>
    </section>
  );
};
