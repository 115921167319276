import * as types from './category.types.js'
import { categoryService } from '../services'
import { showErrorMessage } from '../utils'

export const getCategoriesAsync = (
  successCallback,
  errorCallback
) => {
  return async (dispatch) => {
    dispatch({ type: types.GET_CATEGORIES.REQUEST })
    try {
      const {
        data: { data: _data },
      } = await categoryService.getCategories()
      dispatch({ type: types.GET_CATEGORIES.SUCCESS, payload: _data })
      successCallback?.()
    } catch (err) {
      dispatch({ type: types.GET_CATEGORIES.FAILURE, payload: err })
      console.log('act err', err)
      showErrorMessage(err)
      errorCallback?.()
    }
  }
}
