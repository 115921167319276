import React from "react";
import { Swiper, SwiperSlide } from 'swiper/swiper-react.mjs';
import { EffectCoverflow, Navigation } from 'swiper/modules';
import 'swiper/modules/effect-coverflow.css';
import 'swiper/modules/navigation.css';
import 'swiper/swiper.css';
import { IconChevronRight } from "../../icons/IconChevronRight";
import revolutionStyles from "./Revolution.module.css";

export const Revolution = () => {
  return (
    <section className={revolutionStyles['revolution']}>
      <h2>Each Review Creates A <span>Revolution</span></h2>
      <p>let us embark on this journey of exploration and discovery, where each review is a thread in the tapestry of life, weaving stories that transcend time and space.</p>
      <div className={revolutionStyles['slides']}>
        <Swiper
          effect={'coverflow'}
          grabCursor={true}
          // cssMode
          centeredSlides={true}
          slidesPerView={5}
          spaceBetween={0}
          navigation={{ nextEl: `.${revolutionStyles['slides']} .${revolutionStyles['next']}`, prevEl: `.${revolutionStyles['slides']} .${revolutionStyles['prev']}` }}
          coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth: 115,
            modifier: 1,
            slideShadows: false,
            scale: 1
          }}
          loop
          modules={[EffectCoverflow, Navigation]}
          className={"mySwiper"}
        >
          <SwiperSlide>
            <div className={revolutionStyles['image-card']} style={{ backgroundImage: 'url(/img/three.svg)' }}>
              <div className={revolutionStyles['card-text']}>
                My peter and I had a very good time  on this beautiful day
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={revolutionStyles['image-card']} style={{ backgroundImage: 'url(/img/three.svg)' }}>
              <div className={revolutionStyles['card-text']}>
                Beach Day on My Birthday
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={revolutionStyles['image-card']} style={{ backgroundImage: 'url(/img/three.svg)' }}>
              <div className={revolutionStyles['card-text']}>
                Nature Attracts Me
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={revolutionStyles['image-card']} style={{ backgroundImage: 'url(/img/three.svg)' }}>
              <div className={revolutionStyles['card-text']}>
                Do you notice my new Handbag
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={revolutionStyles['image-card']} style={{ backgroundImage: 'url(/img/three.svg)' }}>
              <div className={revolutionStyles['card-text']}>
                My peter and I had a very good time  on this beautiful day
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={revolutionStyles['image-card']} style={{ backgroundImage: 'url(/img/three.svg)' }}>
              <div className={revolutionStyles['card-text']}>
                Beach Day on My Birthday
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
        <div className={revolutionStyles['next']}>
          <IconChevronRight />
        </div>
        <div className={revolutionStyles['prev']}>
          <IconChevronRight />
        </div>
      </div>
    </section>
  );
};
