import { HttpMethod } from "./baseHttpService";

export default class ReviewService {
  constructor(
    apiService
  ) {
    this.apiService = apiService;
  }

  async postReview(data) {
    const response = await this.apiService.request({
      method: HttpMethod.POST,
      url: `review`,
      payload: data
    });
    return response;
  }

  async updateReview(id, data) {
    const response = await this.apiService.request({
      method: HttpMethod.PATCH,
      url: `review`,
      payload: { id, ...data }
    });
    return response;
  }

  async deleteReview(id) {
    const response = await this.apiService.request({
      method: HttpMethod.DELETE,
      url: `review`,
      payload: { id }
    });
    return response;
  }

  async getReview(id) {
    const response = await this.apiService.request({
      method: HttpMethod.GET,
      url: `review/${id}`,
    });
    return response;
  }

  async getReviews(query, category, cursor) {
    const response = await this.apiService.request({
      method: HttpMethod.GET,
      url: `review?query=${query}&category=${category}&cursor=${cursor}`,
    });
    return response;
  }

  async getCompanyReviews(slug, highlight, page, per_page) {
    const response = await this.apiService.request({
      method: HttpMethod.GET,
      url: `review/company/${slug}?page=${page}&per_page=${per_page}&highlight=${highlight}`,
    });
    return response;
  }

  async getUserReviews(id, page, per_page) {
    const response = await this.apiService.request({
      method: HttpMethod.GET,
      url: `review/user/${id}?page=${page}&per_page=${per_page}`,
    });
    return response;
  }
}


