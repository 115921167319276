.banner {
    background: linear-gradient(90deg, #2E5676 0.09%, #4B4476 105.27%);
    padding: 28px 77px 30px 115px;

    display: flex;
    align-items: center;
}

.banner h1 {
    color: var(--Schemes-On-Primary, #FFF);
    font-family: Outfit;
    font-size: 87px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    margin-bottom: 46px;
}

.banner h1 span {
    font-family: Outfit;
    font-size: 87px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    background: linear-gradient(0deg, #edf200 0%, #edc18e 59.5%) text;
    ;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.banner p {
    color: var(--Schemes-On-Primary, #FFF);
    font-family: Lato;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.left {
    max-width: 600px;
}

.right {
    position: relative;
    flex: 1;
    padding: 40px 0px 40px 76px;
}

.right .eclipse {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;


    border-radius: 100%;
    filter: blur(calc(32px / 2));
    mix-blend-mode: soft-light;
    background-color: #ffffff80;
}

.form {
    padding-top: 230px;
    padding-bottom: 173px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.form .backdrop {
    border-radius: 20px;
    background: url(../../../static/img/contact-form-background-image.svg) lightgray 50% / cover no-repeat, #D9D9D9;
    padding: 34px 45px 45px 45px;
    max-width: 852px;
    width: 100%;

    margin-bottom: 105px;
}

.form .inner-backdrop {
    border-radius: 20px;
    background: rgba(57, 79, 118, 0.52);
    box-shadow: 0px 16px 32px -8px #0c0c0d66;
    padding: 11px 59px 21.5px 59px;
}

.form h2 {
    color: #000;
    font-family: Outfit;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    margin-bottom: 19px;
}


.form form {}

.form .inputs {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    row-gap: 33px;
    column-gap: 17px;
    margin-bottom: 19px;
}

.form form input {
    border-radius: 20px;
    border: 1px solid #000;
    background: var(--Schemes-On-Primary, #FFF);

    padding: 16px 11px;

    color: rgba(0, 0, 0, 0.51);
    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    outline: none;
}

.form form textarea {
    grid-column: 1 / span 2;
    border-radius: 20px;
    border: 1px solid #000;
    background: var(--Schemes-On-Primary, #FFF);

    padding: 16px 11px;

    color: rgba(0, 0, 0, 0.51);
    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    outline: none;

    height: 135px;
    resize: none;
}

.form form button {
    border-radius: 20px;
    border: 1px solid #2E5676;
    background: rgba(46, 86, 118, 0.80);
    padding: 15px 22px;

    color: var(--Schemes-On-Primary, #FFF);
    font-family: Lato;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

}

.support-email {
    display: flex;
    align-items: center;
    gap: 28px;
}

.support-email img {
    height: 50px;
}

.support-email a {
    color: rgba(45, 86, 118, 0.95);
    font-family: Lato;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 1;
}