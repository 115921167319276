.login-container {
    padding: 111px 238px 177px;

    display: flex;
    flex-direction: column;
    align-items: center;

}

.login-container h1 {
    color: #000;
    text-align: center;

    /* M3/Elevation Dark/3 */
    text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.30);
    font-family: Outfit;
    font-size: 60px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    max-width: 847px;

    margin-bottom: 100px;
}

.login-container .form {
    /* height: 200px; */
    width: 100%;

    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));

    /* display: flex; */

    background: url('../../../static/img/Rectangle 37.png');
    background-size: 100% 100%;

    padding: 128px 28px 135px;
}

.login-container .form .left {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
}

.login-container .form .left img {
    width: 385px;
}

.login-container .form .right {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 45px;
}

.login-container .form .right h3 {
    color: #555;
    text-align: center;
    font-family: Poppins;
    font-size: 21px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    margin-bottom: 29px;
}

.form .input-group {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.form .input-group label {
    color: #555;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    margin-bottom: 5px;
}

.form .input-group .input-with-icon {
    border-radius: 8.078px;
    background: #F1F3F6;
    border: none;
    outline: none;
    position: relative;
}

.form .input-group .input-with-icon .icon {
    position: absolute;
    top: 0px;
    right: 0px;
    height: 100%;
    padding: 0px 13.13px;

    border-radius: 8.078px;
    background: #7BCEF0;

    display: flex;
    align-items: center;
}

.form .input-group .input-with-icon .icon img {
    height: 24px;
}

.form .input-group .input-with-icon input {
    border-radius: 8.078px;
    background: #F1F3F6;
    border: none;
    outline: none;
    padding: 17px 61px 17px 11px;

    width: 100%;
}

.form .forgot-password {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 5px;
    margin-bottom: 12px;
}

.form .forgot-password a {
    color: #1E2772;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
}

.login {
    border: none;
    border-radius: 8.078px;
    background: #7BCEF0;
    width: 100%;
    padding: 13px;

    color: var(--Schemes-On-Primary, #FFF);
    text-align: center;
    font-family: Poppins;
    font-size: 16.156px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.divider {
    color: #C2C2C2;
    font-family: Poppins;
    font-size: 14.136px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    margin: 10px 0px;

    display: flex;
    align-items: center;
    width: 100%;
}

.divider .divider-line {
    background: #C2C2C2;
    height: 1px;
    flex: 1;
}

.divider .divider-text {
    margin-left: 15px;
    margin-right: 15px;
}

button.outlined-button {
    border-radius: 8.078px;
    border: 1.01px solid #7BCEF0;
    background-color: transparent;

    width: 100%;
    padding: 13px;

    color: #7BCEF0;
    text-align: center;
    font-family: Lato;
    font-size: 16.156px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

.signup,
.continue-with-google {
    margin-bottom: 16px;
}


.continue-with-google,
.continue-with-facebook {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
}
.continue-with-google img,
.continue-with-facebook img {
    width: 22px;
}