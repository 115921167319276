import React from "react";
import { useEffect } from "react";
import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import { Homepage } from "./screens/Homepage";
import { CompanyPage } from "./screens/CompanyPage";
import { UserProfile } from "./screens/UserProfile";
import { ForBusinesses } from "./screens/ForBusinesses";
import { CategoriesPage } from "./screens/CategoriesPage";
import { AboutUsPage } from "./screens/AboutUsPage";
import { LoginPage } from "./screens/LoginPage";
import { SignupPage } from "./screens/SignupPage";
import { SignupVerification } from "./screens/SignupVerification";
import { Dashboard } from "./screens/Dashboard";
import Guest from "./layouts/Guest";
import Auth from "./layouts/Auth";

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  return (
    <Routes>
      <Route path="/" element={<Guest Component={Homepage} />} />
      <Route path="/dashboard" element={<Auth Component={Dashboard} />} />
      <Route path="/company/:slug" element={<Guest Component={CompanyPage} />} />
      <Route path="/user/:id" element={<Guest Component={UserProfile} />} />
      <Route path="/for-businesses" element={<Guest Component={ForBusinesses} />} />
      <Route path="/categories" element={<Guest Component={CategoriesPage} />} />
      <Route path="/about-us" element={<Guest Component={AboutUsPage} />} />
      <Route path="/login" element={<Guest Component={LoginPage} />} />
      <Route path="/signup" element={<Guest Component={SignupPage} />} />
      <Route path="/auth/verify/:encoded" element={<Guest Component={SignupVerification} />} />
      {/*  <Route path="/signup" element={<SignUpPage />} /> */}
    </Routes>
  );
}

export default App;
