import * as types from "../actions/index";

const initialState = {
  deleted_review: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case types.DELETE_REVIEW.REQUEST:
      return {
        ...state,
        deleted_review: null,
      };
    case types.DELETE_REVIEW.SUCCESS:
      return {
        ...state,
        deleted_review: payload,
      };
    default:
      return state;
  }
};
