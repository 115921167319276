import { HttpMethod } from "./baseHttpService";

export default class CategoryService {
  constructor(
    apiService
  ) {
    this.apiService = apiService;
  }

  async getCategories() {
    const response = await this.apiService.request({
      method: HttpMethod.GET,
      url: `category`,
    });
    return response;
  }
}


