.search-component {
  width: 100%;
  position: relative;
}

.search-component input {
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  padding: 16px 48px 16px 56px;
  outline: none;
  border-radius: 28px;
  border: none;
  color: #49454F;
  width: 100%;
}

.search-component .hamburger-icon {
  position: absolute;
  border: none;
  background-color: transparent;
  top: 7.5px;
  left: 8px;
  display: flex;
  align-items: center;
  padding: 8px;
}

.search-component .search-icon {
  position: absolute;
  border: none;
  background-color: transparent;
  top: 7.5px;
  right: 4px;
  display: flex;
  align-items: center;
  padding: 8px;
}