import * as types from './review.types.js'
import { reviewService } from '../services'
import { showErrorMessage } from '../utils'

export const postReviewsAsync = (
  data,
  successCallback,
  errorCallback
) => {
  return async (dispatch) => {
    dispatch({ type: types.POST_REVIEW.REQUEST })
    try {
      const {
        data: { data: _data },
      } = await reviewService.postReview(data)
      dispatch({ type: types.POST_REVIEW.SUCCESS, payload: _data })
      successCallback?.(_data)
    } catch (err) {
      dispatch({ type: types.POST_REVIEW.FAILURE, payload: err })
      console.log('act err', err)
      showErrorMessage(err)
      errorCallback?.()
    }
  }
}

export const updateReviewsAsync = (
  id,
  data,
  successCallback,
  errorCallback
) => {
  return async (dispatch) => {
    dispatch({ type: types.UPDATE_REVIEW.REQUEST })
    try {
      const {
        data: { data: _data },
      } = await reviewService.updateReview(id, data)
      dispatch({ type: types.UPDATE_REVIEW.SUCCESS, payload: _data })
      successCallback?.(_data)
    } catch (err) {
      dispatch({ type: types.UPDATE_REVIEW.FAILURE, payload: err })
      console.log('act err', err)
      showErrorMessage(err)
      errorCallback?.()
    }
  }
}

export const deleteReviewsAsync = (
  id,
  successCallback,
  errorCallback
) => {
  return async (dispatch) => {
    dispatch({ type: types.DELETE_REVIEW.REQUEST })
    try {
      const {
        data: { data: _data },
      } = await reviewService.deleteReview(id)
      dispatch({ type: types.DELETE_REVIEW.SUCCESS, payload: _data })
      successCallback?.(_data)
    } catch (err) {
      dispatch({ type: types.DELETE_REVIEW.FAILURE, payload: err })
      console.log('act err', err)
      showErrorMessage(err)
      errorCallback?.()
    }
  }
}

export const getReviewsAsync = (
  query,
  category,
  cursor,
  successCallback,
  errorCallback
) => {
  return async (dispatch) => {
    dispatch({ type: types.GET_REVIEWS.REQUEST })
    try {
      const {
        data: { data: _data },
      } = await reviewService.getReviews(query, category, cursor)
      dispatch({ type: types.GET_REVIEWS.SUCCESS, payload: _data })
      successCallback?.(_data)
    } catch (err) {
      dispatch({ type: types.GET_REVIEWS.FAILURE, payload: err })
      console.log('act err', err)
      showErrorMessage(err)
      errorCallback?.()
    }
  }
}

export const getReviewAsync = (
  id,
  successCallback,
  errorCallback
) => {
  return async (dispatch) => {
    dispatch({ type: types.GET_REVIEW.REQUEST })
    try {
      const {
        data: { data: _data },
      } = await reviewService.getReview(id)
      dispatch({ type: types.GET_REVIEW.SUCCESS, payload: _data })
      successCallback?.(_data)
    } catch (err) {
      dispatch({ type: types.GET_REVIEW.FAILURE, payload: err })
      console.log('act err', err)
      showErrorMessage(err)
      errorCallback?.()
    }
  }
}

export const getCompanyReviewsAsync = (
  slug,
  highlight,
  page,
  per_page,
  successCallback,
  errorCallback
) => {
  return async (dispatch) => {
    dispatch({ type: types.GET_COMPANY_REVIEWS.REQUEST })
    try {
      const {
        data: { data: _data },
      } = await reviewService.getCompanyReviews(slug, highlight, page, per_page)
      dispatch({ type: types.GET_COMPANY_REVIEWS.SUCCESS, payload: _data })
      successCallback?.(_data)
    } catch (err) {
      dispatch({ type: types.GET_COMPANY_REVIEWS.FAILURE, payload: err })
      console.log('act err', err)
      showErrorMessage(err)
      errorCallback?.()
    }
  }
}

export const getUserReviewsAsync = (
  id,
  page,
  per_page,
  successCallback,
  errorCallback
) => {
  return async (dispatch) => {
    dispatch({ type: types.GET_USER_REVIEWS.REQUEST })
    try {
      const {
        data: { data: _data },
      } = await reviewService.getUserReviews(id, page, per_page)
      dispatch({ type: types.GET_USER_REVIEWS.SUCCESS, payload: _data })
      successCallback?.(_data)
    } catch (err) {
      dispatch({ type: types.GET_USER_REVIEWS.FAILURE, payload: err })
      console.log('act err', err)
      showErrorMessage(err)
      errorCallback?.()
    }
  }
}
