import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserAsync } from "../../actions/user.action.js";
import ProtectedRoute from "../../utils/Protected.js";

const Auth = ({ Component }) => {
    const { token } = useSelector(({ Auth }) => Auth);
    const dispatch = useDispatch();

    useEffect(() => {
        if (token) {
            dispatch(getUserAsync())
        }
    }, [token])

    return (
        <React.Fragment>
            <ProtectedRoute>
                <Component />
            </ProtectedRoute>
        </React.Fragment>
    );
}

export default Auth;