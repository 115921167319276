import { combineReducers } from 'redux'
import Auth from './auth.reducer'
import Category from './category.reducer'
import Company from './company.reducer'
import General from './general.reducer'
import Review from './review.reducer'
import User from './user.reducer'


const rootReducer = combineReducers({
  Auth,
  Company,
  Category,
  General,
  Review,
  User,
})

export default rootReducer
