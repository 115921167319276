/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

export const Icon10 = ({ color = "#49454F", className }) => {
  return (
    <svg
      className={`icon-10 ${className}`}
      fill="none"
      height="25"
      viewBox="0 0 24 25"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M3 18.7539V16.7539H21V18.7539H3ZM3 13.7539V11.7539H21V13.7539H3ZM3 8.75391V6.75391H21V8.75391H3Z"
        fill={color}
      />
    </svg>
  );
};

Icon10.propTypes = {
  color: PropTypes.string,
};
