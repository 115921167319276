import React from "react";
import { Link } from "react-router-dom";
import footerStyles from "./Footer.module.css";
import clsx from "clsx";

export const Footer = () => {
  return (
    <footer className={footerStyles.footer}>
      <div className={footerStyles['logo-and-links']}>
        <div className={footerStyles['logo-and-featured-image']}>
          <img src="/img/logo.png" alt="" className={footerStyles['logo']} />
          <img src="/img/13818757-5358083-2.png" alt="" className={footerStyles['featured-image']} />
        </div>

        <div className={clsx(footerStyles['about-links'], footerStyles['footer-nav-section'])}>
          <h4>About</h4>
          <Link to="/about-us">About Us</Link>
          <Link to="/jobs">Jobs</Link>
          <Link to="/contact-us">Contact</Link>
        </div>

        <div className={clsx(footerStyles['community-links'], footerStyles['footer-nav-section'])}>
          <h4>Community</h4>
          <Link to="/about-us">Trust Reviews</Link>
          <Link to="/jobs">Help Center</Link>
          <Link to="/contact-us">Log In</Link>
          <Link to="/contact-us">Sign Up</Link>
        </div>

        <div className={clsx(footerStyles['follow-links'], footerStyles['footer-nav-section'])}>
          <h4>Follow us on</h4>
          <div className={footerStyles['social-media-icons']}>
            <img alt="Facebook icon" src="/img/facebook-icon.png" />
            <img alt="Insta icon" src="/img/insta-icon.png" />
            <img alt="Linkedin icon" src="/img/linkedin-icon.png" />
            <img alt="Pintrest" src="/img/pintrest.png" />
            <img alt="Youtube ICON" src="/img/youtube-icon.png" />
            <img alt="Twitter icon" src="/img/twitter-icon.png" />
          </div>
        </div>
      </div>

      <div className={footerStyles['strip']}>
        <div className={footerStyles['copyright']}>© 2024 Qolty, All Rights Reserved</div>
        <div className={footerStyles['other-links']}>
          <Link to="/privacy-policy">Privacy Policy</Link>
          <Link to="/terms-and-conditions">Terms & Conditions</Link>
          <Link to="/cookie-preferences">Cookie Preferences</Link>
        </div>
      </div>
    </footer>
  );
};
