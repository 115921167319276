import React, { useEffect } from "react";
import { Footer } from "../../components/Footer";
import { NavWithSearch } from "../../components/NavWithSearch";
import categoriesPageStyles from "./CategoriesPage.module.css";
import { Categories } from "../../components/Categories";
import { useDispatch, useSelector } from "react-redux";
import { getCategoriesAsync } from "../../actions/category.action";

export const CategoriesPage = () => {
    const dispatch = useDispatch();
    const { categories } = useSelector(({ Category }) => Category);

    useEffect(() => {
        console.log("Page reloaded")
        dispatch(getCategoriesAsync());
    }, [])

    useEffect(() => {
        document.documentElement.classList.add('light-bg')
        return () => document.documentElement.classList.remove('light-bg')
    }, [])

    return (
        <>
            <NavWithSearch />

            <section className={categoriesPageStyles['banner']}>
                <h1>
                    What's On Your <span>Review</span> Radar?
                </h1>

                <img src="/img/categories.png" alt="" />

            </section>

            <div className={categoriesPageStyles['category-box']}>
                <Categories categories={categories} />
            </div>

            <Footer />
        </>
    );
};
