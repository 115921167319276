import { HttpMethod } from "./baseHttpService";

export default class UserService {
  constructor(
    apiService
  ) {
    this.apiService = apiService;
  }

  async getUser() {
    const response = await this.apiService.request({
      method: HttpMethod.GET,
      url: `user`,
    });
    return response;
  }

  async getUserById(id) {
    const response = await this.apiService.request({
      method: HttpMethod.GET,
      url: `user/${id}`,
    });
    return response;
  }

  async updateUser(data) {
    const response = await this.apiService.request({
      method: HttpMethod.PUT,
      url: `user`,
      payload: data,
    });
    return response;
  }

  async updatePassword(data) {
    const response = await this.apiService.request({
      method: HttpMethod.PUT,
      url: `user/password`,
      payload: data,
    });
    return response;
  }
}


