import React, { useEffect, useState } from "react";
import { Footer } from "../../components/Footer";
import { NavWithSearch } from "../../components/NavWithSearch";
import signupVerificationStyles from "./SignupVerification.module.css";
import { useLocation, useParams } from "react-router-dom";
import clsx from "clsx";
import { useDispatch } from "react-redux";
import { verifyAsync } from "../../actions/auth.action";
import base64url from "base64-url";

export const SignupVerification = () => {
    const initialState = {
        email: '',
        code: '',
    }
    const [state, setState] = useState(initialState)
    const [success, setSuccess] = useState(false)
    const dispatch = useDispatch()

    const location = useLocation()

    const params = useParams()

    const onChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.value
        })
    }

    const onSubmit = (event) => {
        event.preventDefault();
        dispatch(verifyAsync(state, () => {
            setSuccess(true)
        }, () => {
            // setSuccess(true)
        }))
    }

    useEffect(() => {
        try {
            const json = atob(base64url.unescape(params.encoded))
            const { email, code } = JSON.parse(json)
            setState({ email, code })
        } catch (error) {
            console.log(error)
        }
    }, [location.pathname, params])

    useEffect(() => {
        document.documentElement.classList.add('light-bg')
        return () => document.documentElement.classList.remove('light-bg')
    }, [])

    return (
        <>
            <NavWithSearch />

            <section className={signupVerificationStyles['login-container']}>
                {/* <h1>Look through. Write down. Discover Organizations</h1> */}

                <div className={signupVerificationStyles['form']}>
                    <div className={signupVerificationStyles['left']}>
                        <img src="/img/login-banner.png" alt="" />
                    </div>
                    {
                        success
                            ?
                            <div className={signupVerificationStyles['right']}>
                                <h3>Verification Successful</h3>

                                <p className={signupVerificationStyles['notice']}>Your account was successfully verified. You can now login with the email and password provided at signup.</p>
                                <button className={signupVerificationStyles['login']} onClick={onSubmit}>
                                    Sign In
                                </button>
                            </div>
                            :
                            <div className={signupVerificationStyles['right']}>
                                <h3>Email Verification</h3>

                                <div className={signupVerificationStyles['input-group']} style={{ marginBottom: 17 }}>
                                    <label htmlFor="">Email</label>
                                    <div className={signupVerificationStyles['input-with-icon']}>
                                        <input
                                            type="text"
                                            name="email"
                                            value={state.email}
                                            onChange={onChange}
                                        />
                                        <div className={signupVerificationStyles['icon']}>
                                            <img src="/img/frame.svg" alt="" />
                                        </div>
                                    </div>
                                </div>

                                <div className={signupVerificationStyles['input-group']} style={{ marginBottom: 17 }}>
                                    <label htmlFor="">Code</label>
                                    <div className={signupVerificationStyles['input-with-icon']}>
                                        <input
                                            type="text"
                                            name="code"
                                            value={state.code}
                                            onChange={onChange}
                                        />
                                        <div className={signupVerificationStyles['icon']}>
                                            <img src="/img/frame-1.svg" alt="" />
                                        </div>
                                    </div>
                                </div>

                                <button className={signupVerificationStyles['login']} onClick={onSubmit}>
                                    Verify Email
                                </button>

                                <div className={signupVerificationStyles['divider']}>
                                    <div className={signupVerificationStyles['divider-line']}></div>
                                    <span className={signupVerificationStyles['divider-text']}>OR</span>
                                    <div className={signupVerificationStyles['divider-line']}></div>
                                </div>

                                <button className={clsx(signupVerificationStyles['outlined-button'], signupVerificationStyles['signup'])}>
                                    Back to login page
                                </button>
                            </div>
                    }
                </div>
            </section>

            <Footer />
        </>
    );
};
