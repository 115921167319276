import React, { useEffect } from "react";
import { Footer } from "../../components/Footer";
import { NavWithSearch } from "../../components/NavWithSearch";
import forBusinessesStyles from "./ForBusinesses.module.css";

export const ForBusinesses = () => {
    useEffect(() => {
        document.documentElement.classList.add('light-bg')
        return () => document.documentElement.classList.remove('light-bg')
    }, [])

    return (
        <>
            <NavWithSearch />

            <section className={forBusinessesStyles['banner']}>
                <div className={forBusinessesStyles['left']}>
                    <h1>
                        <span>Contact us</span> with your thoughts
                    </h1>

                    <p>your feedback drives our progress.</p>
                </div>

                <div className={forBusinessesStyles['right']}>
                    <div className={forBusinessesStyles['eclipse']}></div>
                    <img src="/img/Group 15.png" alt="" />
                </div>

            </section>

            <section className={forBusinessesStyles['form']}>
                <div className={forBusinessesStyles['backdrop']}>

                    <div className={forBusinessesStyles['inner-backdrop']}>
                        <h2>Get in touch</h2>
                        <form action="">
                            <div className={forBusinessesStyles['inputs']}>
                                <input type="text" placeholder="First Name" />
                                <input type="text" placeholder="Last Name" />
                                <input type="text" placeholder="Email" />
                                <input type="text" placeholder="Phone" />
                                <textarea name="" id="" placeholder="Message"></textarea>
                            </div>
                        <button>Send Message</button>
                        </form>
                    </div>
                </div>

                <div className={forBusinessesStyles['support-email']}>
                    <img src="/img/mingcute-mail-fill.svg" alt="" />
                    <a href="">admin@qolty.com</a>
                </div>
            </section>


            <Footer />
        </>
    );
};
