import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserAsync } from "../../actions/user.action.js";

const Guest = ({ Component }) => {
    const { token } = useSelector(({ Auth }) => Auth);
    const dispatch = useDispatch();

    useEffect(() => {
        if (token) {
            dispatch(getUserAsync())
        }
    }, [token])

    return (
        <React.Fragment>
            <Component />
        </React.Fragment>
    );
}

export default Guest;