import React, { useEffect } from "react";
import { Footer } from "../../components/Footer";
import { Revolution } from "../../components/Revolution";
import { GetThrough } from "../../components/GetThrough";
import { LatestReviews } from "../../components/LatestReviews";
import { ExploreCategories } from "../../components/ExploreCategories";
import { HomeBanner } from "../../components/HomeBanner";
import { HomeNav } from "../../components/HomeNav";
import { useDispatch } from "react-redux";
import { getLandingDataAsync } from "../../actions/general.action";

export const Homepage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getLandingDataAsync(() => {
      console.log("Homepage data loaded")
    }))
  }, []);

  return (
    <>
      <HomeNav />

      <HomeBanner />

      <ExploreCategories />

      <LatestReviews />

      <GetThrough />

      <Revolution />

      <Footer />
    </>
  );
};
