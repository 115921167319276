import ApiService from './axios.service'
import AuthService from './auth.service'
import CompanyService from './company.service'
import CategoryService from './category.service'
import GeneralService from './general.service'
import ReviewService from './review.service'
import UserService from './user.service'

export const authService = new AuthService(
  new ApiService(process.env.REACT_APP_API_URL)
)

export const categoryService = new CategoryService(
  new ApiService(process.env.REACT_APP_API_URL)
)

export const companyService = new CompanyService(
  new ApiService(process.env.REACT_APP_API_URL)
)

export const generalService = new GeneralService(
  new ApiService(process.env.REACT_APP_API_URL)
)

export const reviewService = new ReviewService(
  new ApiService(process.env.REACT_APP_API_URL)
)

export const userService = new UserService(
  new ApiService(process.env.REACT_APP_API_URL)
)